import * as qiniu from 'qiniu-js'
import * as apiRes from '@/api/resource'

const Qiniu = {

    async uploadFile(file, dir = 'images', doneCallback = null, errCallback = null) {
        if (!file) {
            return
        }

        // 获取token
        let res = await apiRes.getUploadInfo();
        let tokenInfo = res.data
        if (!tokenInfo.uptoken || !tokenInfo.uuid) {
            return
        }

        let token = tokenInfo.uptoken
        let uuid = tokenInfo.uuid
        let key = ''
        let ext = file.name.split(".")[1]
        if (ext == 'mp3' || ext == 'wav' || ext == 'mp4') {
            key = 'assets/' + dir + '/' + tokenInfo.uuid + '.' + ext;
        } else {
            key = 'assets/' + dir + '/' + tokenInfo.uuid + '/get/';
        }

        let config = {
            useCdnDomain: true,
            disableStatisticsReport: true,
            retryCount: 3,
        };

        if (file) {
            // 设置next,error,complete对应的操作，分别处理相应的进度信息，错误信息，以及完成后的操作
            let error = function(err) {
                // console.log(err);
                console.log("上传出错")
                if (errCallback) {
                    errCallback()
                }
            };

            // eslint-disable-next-line
            let finishedAttr = [];
            // eslint-disable-next-line
            let compareChunks = [];

            let complete = function(res) {
                // console.log('上传完成！');
                // console.log(res)
                // 显示上传完成回调
                if (doneCallback) {
                    doneCallback(uuid)
                }
            };
            let next = function(response) {
                let chunks = response.chunks||[];
                let total = response.total;
                // 这里对每个chunk更新进度，并记录已经更新好的避免重复更新，同时对未开始更新的跳过
                for (let i = 0; i < chunks.length; i++) {
                    if (chunks[i].percent === 0 || finishedAttr[i]){
                        continue;
                    }
                    if (compareChunks[i].percent === chunks[i].percent){
                        continue;
                    }
                    if (chunks[i].percent === 100){
                        finishedAttr[i] = true;
                    }
                    // console.log('percent: ' + chunks[i].percent);
                }
                compareChunks = chunks;
            };

            let subObject = {
                next: next,
                error: error,
                complete: complete
            };
            let subscription;


            let putExtra = {
                fname: '',
                params: {},
                mimeType: ["image/png", "image/jpeg", "image/jpg", "image/gif", "audio/mp3", "audio/wav"]
            };
            putExtra.params["x:name"] = key;
            
            // 调用sdk上传接口获得相应的observable，控制上传和暂停
            let observable = qiniu.upload(file, key, token, putExtra, config);
            subscription = observable.subscribe(subObject);

            // 取消上传
            // subscription.unsubscribe();
        }
    },
}

export default Qiniu;
